<template>
  <div class="container-fluid" style="margin: 126px 0; position: relative">
    <Plan ref="savePlanModal" @updatePlan="updatePlan" />
    <Toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="10000"
      @clearToast="clearToast"
    />
    <div>
      <ul class="breadcrumb ProximaNovaRegular">
        <li>
          <span>Model Results</span>
        </li>
        <li>
          <span>Sale Statistics</span>
        </li>
        <li>
          <span>Future Planning</span>
        </li>
        <li class="ProximaNovaBold">Planning Results</li>
      </ul>
    </div>
    <div
      class="d-flex mt-4"
      :class="[expand ? 'justify-content-end' : 'justify-content-between']"
    >
      <div
        class="d-none d-md-block card arrow-card"
        :class="[expand ? 'arrow-card-collapse' : '']"
        @click="toggleExpand"
      >
        <i v-if="!expand" class="fas fa-arrow-left arrow"></i>
        <i v-else class="fas fa-arrow-right arrow-white"></i>
      </div>
      <div :class="[expand ? 'btn-group' : '']">
        <button class="btn-effect-outline" @click="gotoScenarioPlan">
          Scenerio Planning
        </button>
        <button
          class="btn-effect ml-5"
          @click="$refs.savePlanModal.open('add')"
        >
          <span class="mr-5">Save Plan</span>
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>

    <b-row>
      <b-col cols="12" md="4" :class="[expand ? 'd-none' : '']">
        <div class="card">
          <div class="card-body p-0">
            <div class="card-title">Prediction</div>
            <div class="mt-5">
              <w-tab
                :tabName="activePredictionTab"
                :tabs="predictionTab"
                @activeTab="changePredictionTab($event)"
              >
              </w-tab>
            </div>
            <b-container fluid>
              <SalesGrowth
                :bigRangeSize="true"
                v-if="activePredictionTab === 'Revenue Based'"
                :sliderValue="sliderRevenueValue"
                :data="revenueData"
              />
              <SalesGrowth
                :bigRangeSize="true"
                v-if="activePredictionTab === 'Budget Based'"
                :sliderValue="sliderBudgetValue"
                :data="budgetData"
              />
            </b-container>
          </div>
        </div>
        <div class="card mt-5 mb-5 mb-md-0">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-12">
                <details class="">
                  <Summary class="Additional-details"
                    >Additional Details</Summary
                  >
                  <b-container fluid>
                    <div
                      v-for="(detail, index) in additionalDetails"
                      :key="index"
                      class="row additonal-detail py-5 px-4"
                      :class="[!detail.select ? 'border-top' : 'detail-bg']"
                    >
                      <b-col cols="10">
                        <div class="details-heading">{{ detail.name }}</div>
                        <div v-if="detail.select">
                          <label>Product</label>
                          <b-form-select
                            v-model="detail.product"
                            :options="detail.dropdownList"
                          ></b-form-select>
                          <label class="mb-0">Date</label>
                          <div>
                            <w-datepicker
                              :placeHolder="'Select'"
                              :underLine="true"
                              :labelText="''"
                              @input="selectedDate(index, $event)"
                            ></w-datepicker>
                          </div>
                          <label>Value in numbers</label>
                          <b-form-input
                            v-model="detail.value"
                            type="number"
                          ></b-form-input>
                        </div>
                      </b-col>
                      <b-col cols="2">
                        <input
                          :name="`checkbox-${index}`"
                          type="checkbox"
                          class="form-check-input"
                          :value="detail.select"
                          @input="updateCheckbox(index, $event.target.checked)"
                        />
                      </b-col>
                    </div>
                  </b-container>
                </details>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="expand ? 12 : 8">
        <div class="card mb-4">
          <div class="card-body p-0">
            <div class="card-title">Prediction</div>
            <b-container fluid class="wrapper-statistics">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  :md="expand ? 2 : 4"
                  v-for="(data, index) in predictionData"
                  :key="index"
                  class="item"
                >
                  <Statistics
                    :data="data"
                    :index="(index + 1)% 3"
                    :specialChar="data.specialChar"
                    :img="data.image"
                  />
                  <!-- <div
                    class="item-img"
                    
                  >
                    <i class="fas fa-chart-pie" style="font-size: 24px"></i>
                  </div>

                  <div class="item-text">
                    <div class="item-text__title">{{ data.label }}</div>
                    <div class="item-text__subtitle">${{ data.value }}M</div>
                  </div> -->

                  <div
                    v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                      (expand && (index + 1) % 6 !== 0)
                    "
                    class="item-right-line"
                  ></div>
                </b-col>
              </b-row>
              <b-row class="extra-wrapper-statistics">
                <b-col
                  cols="12"
                  sm="6"
                  :md="expand ? 2 : 4"
                  v-for="(data, index) in predictionExtraData"
                  :key="index"
                  class="item"
                >
                  <Statistics
                    :data="data"
                    :index="(index + 1)% 3"
                    :img="data.image"
                    :specialChar="data.specialChar"
                  />
                  <div
                    v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                      (expand && (index + 1) % 6 !== 0)
                    "
                    class="item-right-line"
                  ></div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-body p-3">
            <ColumnChart :chartData="columnChartData" />
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-body pt-2">
            <Digital
              :data="digitalExpandData"
              :stripData="digitalData"
              subtitle="Recommended Digital Mix"
              @getDataBreakdown="getDataBreakdown"
              total="75"
            />
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body pt-2">
            <Content
              :barData="contentExpandData"
              :stripData="contentData"
              subtitle="Recommended Content Mix"
              total="35"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-body p-3">
            <LineChart :chartData="lineChartData" />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Tab from "@/components/Solutions/Tab.vue";
import SalesGrowth from "@/components/Solutions/SalesGrowth.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import LineChart from "@/components/Chart/LineChart.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Plan from "@/components/Modal/Plan.vue";
import Toast from "@/components/Toast/Toast.vue";
import DatePicker from "@/widgets/DatePicker.vue";
import Statistics from "@/components/Statistics.vue";
export default {
  name: "PlanningResults",
  components: {
    "w-tab": Tab,
    "w-datepicker": DatePicker,
    SalesGrowth,
    Digital,
    Content,
    LineChart,
    ColumnChart,
    Plan,
    Toast,
    Statistics,
  },
  data() {
    return {
      expand: false,
      predictionTab: [
        {
          category: "Revenue Based",
        },
        {
          category: "Budget Based",
        },
      ],
      activePredictionTab: "Revenue Based",
      sliderRevenueValue: 16,
      revenueData: [{ name: "Expected Revenue", value: 621 }],
      sliderBudgetValue: 46,
      budgetData: [{ name: "Expected Budget", value: 621 }],
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 2),
              x2: Date.UTC(2014, 11, 10),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 10),
              x2: Date.UTC(2014, 11, 23),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 23),
              x2: Date.UTC(2014, 11, 30),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      digitalData: [
        {
          label: "Google Analytics",
          value: 35,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 20,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 8,
          backgroundColor: "#148f3a",
        },
        {
          label: "Linkedin",
          value: 9,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 15,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 13,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 23,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 77,
          backgroundColor: "#b5a9ff",
        },
      ],
      contentExpandData: {
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],

        title: {
          text: "",
        },
        xAxis: {
          categories: [""],
          title: {
            text: null,
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#8394b4",
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Direct Posts",
            data: [10],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Product reviews",
            data: [31],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Influencer mentions",
            data: [63],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Interviews",
            data: [20],
            pointWidth: 16,
            yAxis: 1,
          },
        ],
      },
      predictionData: [
        {
          label: "Annual Target",
          value: "568",
          specialChar: true,
           image: "click.svg",
        },
        {
          label: "Yearly Budget",
          value: "768",
          specialChar: true,
          image: "file-paper-fill.svg",
        },
        {
          label: "Growth",
          value: "10.0",
          specialChar: false,
          image: "funds-fill.svg"
        },
      ],
      predictionExtraData: [
        {
          label: "Month 1 Target",
          value: "206.18",
          specialChar: true,
          image: "click.svg",
        },
        {
          label: "Month 1 Revenue",
          value: "188.45",
          specialChar: true,
          image: "file-paper-fill.svg",
        },
        {
          label: "Variance",
          value: "-8.6",
          specialChar: false,
          image: "funds-fill.svg"
        },
        {
          label: "Month 2 Target",
          value: "196.18",
          specialChar: true,
          image: "click.svg",
        },
        {
          label: "Month 2 Revenue",
          value: "191.35",
          specialChar: true,
          image: "file-paper-fill.svg",
        },
        {
          label: "Variance",
          value: "-2.8",
          specialChar: false,
          image: "funds-fill.svg"
        },
        {
          label: "Month 3 Target",
          value: "12",
          specialChar: true,
          image: "click.svg",
        },
        {
          label: "Month 3 Revenue",
          value: "12",
          specialChar: true,
          image: "file-paper-fill.svg",
        },
        {
          label: "Variance",
          value: "0",
          specialChar: false,
          image: "funds-fill.svg"
        },
      ],
      lineChartData: {
        series: [
          {
            data: [
              { x: Date.UTC(2020, 1, 31), y: 1 },
              { x: Date.UTC(2020, 2, 31), y: 3 },
              { x: Date.UTC(2020, 3, 31), y: 15 },
              { x: Date.UTC(2020, 4, 31), y: 4 },
              { x: Date.UTC(2020, 5, 31), y: 15 },
              { x: Date.UTC(2020, 6, 31), y: 14 },
              { x: Date.UTC(2020, 7, 31), y: 23 },
              { x: Date.UTC(2020, 8, 31), y: 42 },
              { x: Date.UTC(2020, 9, 31), y: 25 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Previous Spend",
          },
          {
            data: [
              { x: Date.UTC(2020, 1, 31), y: 10 },
              { x: Date.UTC(2020, 2, 31), y: 3 },
              { x: Date.UTC(2020, 3, 31), y: 55 },
              { x: Date.UTC(2020, 4, 31), y: 4 },
              { x: Date.UTC(2020, 5, 31), y: 1 },
              { x: Date.UTC(2020, 6, 31), y: 40 },
              { x: Date.UTC(2020, 7, 31), y: 27 },
              { x: Date.UTC(2020, 8, 31), y: 7 },
              { x: Date.UTC(2020, 9, 31), y: 2 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Predicted Spend",
          },
        ],
        title: {
          text: "Media - Previous vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Recommended Budget Mix",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "sales growth %",
            style: {
              color: "#8394b4",
            },
          },
        },
        xAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          type: "datetime",
        },
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      columnChartData: {
        data: [
          {
            name: "Digital",
            data: [75, 100],
          },
          {
            name: "Content",
            data: [45, 50],
          },
        ],
        title: {
          text: "Level 1 - Previous Vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Budget",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#ffdd7c"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 20,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        xAxis: {
          categories: ["Previous", "Predicted"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          // headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          // pointFormat:
          //   '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
          //   '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          // footerFormat: "</table>",
          // shared: true,
          // useHTML: true,
          // shadow: false,
          // style: {
          //   padding: 0,
          // },
        },
      },
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          date: "",
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
    };
  },
  mounted() {
    console.log(Date.UTC(2014, 11, 2) - Date.UTC(2014, 10, 21));
  },
  methods: {
    getDataBreakdown(data) {
      console.log(data);
    },
    changePredictionTab(tabName) {
      this.activePredictionTab = tabName;
    },
    toggleExpand() {
      this.expand = !this.expand;
    },
    updateCheckbox(index, val) {
      this.additionalDetails[index].select = val;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updatePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, our system has saved your plan successfully",
      };
    },
    gotoScenarioPlan() {
      // this.$router.push({name:'ScenarioPlanning'});
      this.$router.push({
        name: "ScenarioPlanning",
        params: { digitalData: this.digitalData },
      });
    },
    selectedDate(index, e) {
      this.additionalDetails[index].date = e;
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}
.card-title {
  margin: 24px 0px 0px 24px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card-body {
  padding: 24px;
}

.arrow-card {
  width: 74px;
  height: 74px;
  padding: 25px;
  box-shadow: 0 0 8px 0 #c9ced5;
  margin-top: 17px;
}

.arrow-card-collapse {
  background-color: black;
  position: absolute;
  left: 0;
  margin-top: 0px;
}

.arrow {
  font-size: 18px;
}

.arrow-white {
  color: #fff;
  font-size: 18px;
}

.btn-group {
  margin-bottom: 31px;
}

.btn-effect {
  width: 183px;
  height: 60px;
  font-family: ProximaNovaRegular;
  border-radius: 3px;
  background-color: #050505;
  border: solid 2px #050505;
  font-size: 18px;
  color: #fff;
}

.btn-effect-outline {
  max-width: 183px;
  height: 60px;
  border-radius: 3px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: 18px;
  color: #222a37;
  background-color: transparent;
}

.wrapper-statistics {
  padding: 0px 16px;
}

.extra-wrapper-statistics {
  border-top: 1px solid #e8edf5;
  background-color: #f6faff;
}

.item {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

/* .item:not(:nth-child(3n)):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
} */

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.blue-pastel {
  background: #67e8ff;
}

.green-pastel {
  background: #81f89e;
}

.pink-pastel {
  background: #faacfe;
}

.red-pastel {
  background: #ffa2a2;
}

.yellow-pastel {
  background: #ffdd7c;
}

.Additional-details {
  width: 259px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
  padding: 28px 24px;
}

.details-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
label {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
  margin-top: 37px;
}
input[type="number"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  background-color: #eff5ff;
}

/* input[type="text"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  width: 100%;
  background-color: #eff5ff;
} */
select {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  padding-left: 0;
  background-color: #eff5ff;
}
>>> option {
  color: #222a37;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}
select:focus {
  border: none;
  border-bottom: 1px solid black;
  box-shadow: none;
  outline: none;
}
.detail-bg {
  background-color: #eff5ff;
}
</style>